import axios from "axios";

const BASEURL = "https://api.ismvirtualcenter.org/api/";

const instance = axios.create({
  baseURL: BASEURL,
});

const getAccessToken = () => {
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key.startsWith("user_") && key.endsWith("_access_token")) {
      const userId = key.substring(5, key.lastIndexOf("_"));
      return localStorage.getItem(key);
    }
  }
  return null; // Return null if access token not found
};

// Function to get the authenticated axios instance
const getAuthInstance = () => {
  const accessToken = getAccessToken();
  const authInstance = axios.create({
    baseURL: BASEURL,
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  return authInstance;
};

const login = async (data) => {
  const res = await instance.post("auth/login-register", data);
  return res.data;
};

const createVirtualCenter = async (data) => {
  const accessToken = getAccessToken();
  const instance = getAuthInstance(accessToken);
  const res = await instance.post("virtual-center/create", data);
  return res.data;
};

const getVirtualCenter = async (data) => {
  const accessToken = getAccessToken();
  const instance = getAuthInstance(accessToken);
  const res = await instance.get("virtual-center/all-virtual-center");
};

const getVirtualCenterRequest = async () => {
  const accessToken = getAccessToken();
  const instance = getAuthInstance(accessToken);
  const res = await instance.get(
    "/virtual-center-request/all-virtual-center-request"
  );
  return res.data;
};

const createVirtualCenterRequest = async (data) => {
  const accessToken = getAccessToken();
  const instance = getAuthInstance(accessToken);
  const res = await instance.post("virtual-center-request/create", data);
  return res.data;
};

const VirtualCenterMembers = async (data) => {
  const accessToken = getAccessToken();
  const instance = getAuthInstance(accessToken);
  const res = await instance.post("virtual-center/invitee", data);
  return res.data;
};

const followVirtualCenter = async (data) => {
  const accessToken = getAccessToken();
  const instance = getAuthInstance(accessToken);
  const res = await instance.post("virtual-center/follow-virtual-center", data);
  return res.data;
};

const unFollowVirtualCenter = async (data) => {
  const accessToken = getAccessToken();
  const instance = getAuthInstance(accessToken);
  const res = await instance.post(
    "virtual-center/unfollow-virtual-center",
    data
  );
  return res.data;
};

const applySalvation = async (data) => {
  const accessToken = getAccessToken();
  const instance = getAuthInstance(accessToken);
  const res = await instance.post("salvation/apply", data);
  return res.data;
};

const applyPartner = async (data) => {
  const accessToken = getAccessToken();
  const instance = getAuthInstance(accessToken);
  const res = await instance.post("partner/apply", data);
  return res.data;
};

const logCountries = async (data) => {
  const accessToken = getAccessToken();
  const instance = getAuthInstance(accessToken);
  const res = await instance.post("virtual-center/log-lang", data);
  return res.data;
};

const getStreamLinks = async () => {
  const accessToken = getAccessToken();
  const instance = getAuthInstance(accessToken);
  const res = await instance.post("virtual-center/stream-links");
  return res.data;
};

const virtualCenterFollowed = async () => {
  const accessToken = getAccessToken();
  const instance = getAuthInstance(accessToken);
  const res = await instance.post("virtual-center/virtual-center-followers");
  return res.data;
};

export {
  login,
  getAuthInstance,
  createVirtualCenter,
  getVirtualCenter,
  getVirtualCenterRequest,
  createVirtualCenterRequest,
  VirtualCenterMembers,
  followVirtualCenter,
  unFollowVirtualCenter,
  applySalvation,
  applyPartner,
  logCountries,
  getStreamLinks,
  virtualCenterFollowed,
};
