import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { login } from "../Services/ApiServices";

const initialState = {
  loading: false,
  error: false,
  user: [],
  token: "",
  // linkHistory: "",
  isLoggedIn: false,
  isRegistered: false,
};

export const loginUser = createAsyncThunk("user/loginUser", async (data) => {
  try {
    let response;
    if (data.loginType === "default") {
      response = await login({
        full_name: data.full_name,
        email: data.email,
        participants: data.participants,
      });
      console.log(response);
    } else if (data.loginType === "kingsChat") {
    }
    // const response = await login(data);
    // console.log(response);
    return response;
  } catch (error) {
    console.log(error);
  }
});

const userSlice = createSlice({
  name: "user",
  initialState,
  extraReducers: {
    [loginUser.pending]: (state) => {
      state.loading = true;
    },
    [loginUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.user = action.payload?.data.original;
      state.token = action.payload?.token?.original?.access_token;
      state.isLoggedIn = true;
      state.isRegistered = true;
    },
    [loginUser.rejected]: (state) => {
      state.loading = false;
      state.error = true;
      state.isRegistered = false;
    },
  },
});

export default userSlice.reducer;
