import { Space } from "antd";
import "../../Admin/src/App.css"
import AppFooter from "../src/Components/AppFooter";
import AppHeader from "../src/Components/AppHeader";
import PageContent from "../src/Components/PageContent";
import SideMenu from "../src/Components/SideMenu";
import { Outlet } from "react-router-dom";

function DashboardLayout() {
  return (
    <div className="App">
      <AppHeader />
      <div className="SideMenuAndPageContent" >
        <SideMenu />
        <Outlet />
      </div>
      <AppFooter />
    </div>
  );
}
export default DashboardLayout;
