import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import { getVirtualCenter } from "../Services/ApiServices";

import axios from "axios";

const BASEURL = "https://api.ismvirtualcenter.org/api/";

const instance = axios.create({
  baseURL: BASEURL,
});

const getAccessToken = () => {
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key.startsWith("user_") && key.endsWith("_access_token")) {
      const userId = key.substring(5, key.lastIndexOf("_"));
      return localStorage.getItem(key);
    }
  }
  return null; // Return null if access token not found
};

// Function to get the authenticated axios instance
const getAuthInstance = () => {
  const accessToken = getAccessToken();
  const authInstance = axios.create({
    baseURL: BASEURL,
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  return authInstance;
};

const initialState = {
  loading: false,
  error: false,
  virtualCenter: [],
  Lists: [],
  isCreated: false,
};

export const createVirtualCenter = createAsyncThunk(
  "virtualCenter/createVirtualCenter",
  async (data) => {
    const instance = getAuthInstance();
    try {
      const res = await instance.post("virtual-center/create", data);
      console.log(res);
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const getVirtualCenter = createAsyncThunk(
  "virtualCenter/getVirtualCenter",
  async () => {
    const accessToken = getAccessToken();
    const instance = getAuthInstance(accessToken);
    try {
      const res = await instance.get("virtual-center/all-virtual-center");
      console.log(res);
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const virtualCenterFollowed = createAsyncThunk(
  "virtualCenter/followedVirtualCenter",
  async (data) => {
    const accessToken = getAccessToken();
    const instance = getAuthInstance(accessToken);
    try {
      const res = await instance.post(
        "virtual-center/virtual-center-followers",
        data
      );
      console.log(res);
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

const virtualCenterSlice = createSlice({
  name: "virtualCenter",
  initialState,
  extraReducers: {
    [createVirtualCenter.pending]: (state) => {
      state.loading = true;
    },
    [createVirtualCenter.fulfilled]: (state, payload) => {
      state.loading = false;
      state.virtualCenter = payload;
      state.isCreated = true;
    },
    [createVirtualCenter.rejected]: (state) => {
      state.loading = false;
      state.error = true;
      state.isCreated = false;
    },
    [getVirtualCenter.pending]: (state) => {
      state.loading = true;
    },
    [getVirtualCenter.fulfilled]: (state, payload) => {
      state.loading = false;
      state.virtualCenter = payload?.data;
    },
    [getVirtualCenter.rejected]: (state, payload) => {
      state.loading = false;
      state.error = true;
    },
    [virtualCenterFollowed.pending]: (state) => {
      state.loading = true;
    },
    [virtualCenterFollowed.fulfilled]: (state, payload) => {
      state.loading = false;
      state.Lists = payload?.data;
    },
    [virtualCenterFollowed.rejected]: (state, payload) => {
      state.loading = false;
      state.error = true;
    },
  },
});

export default virtualCenterSlice.reducer;
