import {
  UserOutlined,
  UsergroupAddOutlined,
  FireOutlined,  
  ReadOutlined,
  ClusterOutlined  
} from "@ant-design/icons";
import { Card, Space, Statistic, Typography } from "antd";
import {  useState, useEffect } from "react";
// import { getCenters, getAllUsers, getPrayerAndTest, getSalvation, getPartners } from "../../API";
import { getCenters, getAllUsers, } from "../../API";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function AdminDashboard() {
  // const [centers] = useState(0);
  const [prayerAndTest] = useState(0);
  const [salvation] = useState(0);
  const [partners] = useState(0);


  const [centers, setCenters] = useState(0);
  const [allUsers, setAllUsers] = useState(0);
  // const [prayerAndTest, setPrayerAndTest] = useState(0);
  // const [salvation, setSalvation] = useState(0);
  // const [partners, setPartners] = useState(0);

  useEffect(() => {
    getCenters().then((res) => {
      setCenters(res.data.length); 
    
    });

    console.log(centers, "new centers")
    getAllUsers().then((res) => {
      setAllUsers(res.data.length);   
    });
  //   getPrayerAndTest().then((res) => {
  //     setPrayerAndTest(res.data.length());
  //   });
  //   getSalvation().then((res) => {
  //     setSalvation(res.data.length());
  //   });
  //   getPartners().then((res) => {
  //     setPartners(res.data.length());
  //   });
  }, []);

  return (
    <Space size={20} direction="vertical">
      <Typography.Title level={4}>Overview</Typography.Title>
      <Space direction="horizontal">
        <DashboardCard
          icon={
            <ClusterOutlined
              style={{
                color: "green",
                backgroundColor: "rgba(0,255,0,0.25)",
                borderRadius: 20,
                fontSize: 24,
                padding: 8,
              }}
            />
          }
          title={"Centers"}
          value={centers}
        />
        <DashboardCard
          icon={
            <UserOutlined
              style={{
                color: "blue",
                backgroundColor: "rgba(0,0,255,0.25)",
                borderRadius: 20,
                fontSize: 24,
                padding: 8,
              }}
            />
          }
          title={"AllUsers"}
          value={allUsers}
        />
        <DashboardCard
          icon={
            <ReadOutlined
              style={{
                color: "purple",
                backgroundColor: "rgba(0,255,255,0.25)",
                borderRadius: 20,
                fontSize: 24,
                padding: 8,
              }}
            />
          }
          title={"Attendance"}
          value={prayerAndTest}
        />
        <DashboardCard
          icon={
            <FireOutlined
              style={{
                color: "red",
                backgroundColor: "rgba(255,0,0,0.25)",
                borderRadius: 20,
                fontSize: 24,
                padding: 8,
              }}
            />
          }
          title={"Salvation"}
          value={salvation}
        />
         {/* <DashboardCard
          icon={
            <UsergroupAddOutlined
              style={{
                color: "red",
                backgroundColor: "rgba(255,0,0,0.25)",
                borderRadius: 20,
                fontSize: 24,
                padding: 8,
              }}
            />
          }
          title={"Partners"}
          value={partners}
        /> */}

      </Space>
    </Space>
  );
}

function DashboardCard({ title, value, icon }) {
  return (
    <Card>
      <Space direction="horizontal">
        {icon}
        <Statistic title={title} value={value} />
      </Space>
    </Card>
  );
}

export default AdminDashboard;
