import React, { useEffect, useState } from 'react';
import { Input, Button, Form } from 'antd';
import { useToast } from '@chakra-ui/react'
import Typography from 'antd/es/typography/Typography';
import axios from 'axios';
import ThreeDots from "react-loading-icons/dist/esm/components/three-dots";
import "../../App.css"


function LiveStream() {
  const toast = useToast();
  const [stream_link, setStream_link] = useState('');
  const [stream_link_1, setStream_link_1] = useState('');
  const [stream_link_2, setStream_link_2] = useState('');
  const [stream_link_3, setStream_link_3] = useState('');
  const [isLoading, setIsLoading] = useState(false)
  const token = localStorage.getItem("token")


  function showToast(title, msg, status) {
    toast({
      title: title,
      description: msg,
      status: status,
      duration: 5000,
      isClosable: true,
      position: "top"
    });
  }


  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true)

    const formData = {
      stream_link,
      stream_link_1,
      stream_link_2,
      stream_link_3
    };
    // const authToken = 'your_auth_token'; // Replace with your authentication token

    try {
      const response = await axios.post('https://api.ismvirtualcenter.org/api/admin/stream-link/create', formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      console.log("response", response);
      if (response?.data?.status_code === 200) {
        showToast('Success', 'Uploaded Successfully', 'success');
        setIsLoading(false)
        console.log('Response:', response.data);
      } else {
        showToast('Fail', 'Upload Failed', 'error');
        setIsLoading(false)
        // Handle registration error
        console.error('Registration failed');
      }
      // Handle the response from the API
    } catch (error) {
      console.error('Error:', error);
      // Handle errors
    }

    // Reset the form
    // stream_link_1('');
    // stream_link_2('');
    // stream_link_3('');
    // stream_link_4('');
  };




  return (
    <div >

      <form onSubmit={handleSubmit} className="my-form">
        <div style={{ display: "flex", flexDirection: "column", gap: "30px", marginTop: "50px", marginLeft: "20px" }}>
          <input style={{ padding: "10px", width: "400px" }}
            type="text"
            required
            value={stream_link}
            onChange={(e) => setStream_link(e.target.value)}
            placeholder="Enter Live Stream link 1"
          />
          <input style={{ padding: "10px", width: "400px" }}
            type="text"
            // required
            value={stream_link_1}
            onChange={(e) => setStream_link_1(e.target.value)}
            placeholder="Enter Live Stream link 2"
          />
          <input style={{ padding: "10px", width: "400px" }}
            type="text"
            // required
            value={stream_link_2}
            onChange={(e) => setStream_link_2(e.target.value)}
            placeholder="Enter Live Stream link  3"
          />
          <input style={{ padding: "10px", width: "400px" }}
            type="text"
            // required
            value={stream_link_3}
            onChange={(e) => setStream_link_3(e.target.value)}
            placeholder="Enter Live Stream link  4"
          />
          <button type="submit" disabled={isLoading} style={{
            padding: "10px", width: "400px", background: "rgb(63,94,251)",
            background: "radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 53%)", color: "white"
          }}> {!isLoading ? <span>Submit</span> : <ThreeDots height="1rem" fill="#fff" />}</button>

        </div>
      </form>





    </div>
  );
};


export default LiveStream