import { Image, Typography } from "antd";
import logo from "./logo.jpeg"
 

function AppHeader() {
  return (
    <div className="AppHeader">
        <Image
        width={40}
        src={logo}
      ></Image>
      <Typography.Title  className="AppHeaderTypo">ISM Admin Dashboard</Typography.Title>
    </div>
  );
}
export default AppHeader;
