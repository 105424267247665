import React, { Suspense, lazy } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
// import AdminDashBoard from "./Admin/src/AdminDashBoard";
import { LoginPage } from "./Admin/src/Pages/Login/LoginPage";
import AdminLayout from "./Admin/layout/AdminLayout";
import Salvation from "./Admin/src/Pages/Salvation";
import Partner from "./Admin/src/Pages/Partner";
import Users from "./Admin/src/Pages/Users";
import Centers from "./Admin/src/Pages/Centers";
import PrayerRequestAndTestimonies from "./Admin/src/Pages/PrayerRequest/Testimonies";
import DashboardLayout from "./Admin/layout/DashboardLayout";
import AdminDashboard from "./Admin/src/Pages/AdminDashboard";
import LiveStream from "./Admin/src/Pages/LiveStream/Index";
import LanguageLogs from "./Admin/src/Pages/LanguageLogs";
import {ProtectedRoute} from "./Admin/src/Pages/AdminDashboard/ProtectedRoute"
// import { ChakraProvider, extendTheme } from "@chakra-ui/react";
// import { ToastProvider, useToast } from "@chakra-ui/react";


const Login = lazy(() => import("./Components/Auth/Login"));
const Dashboard = lazy(() => import("./Components/Dashboard/Dashboard"));
const VirtualCenter = lazy(() =>
  import("./Components/VirtualCenter/VirtualCenter")
);
const NewVirtualCenter = lazy(() =>
  import("./Components/VirtualCenter/NewVirtualCenter")
);

// const theme = extendTheme({
//   config: {
//     toast: {
//       position: "top",
//     },
//   },
// });

function App() {
  return (
    <ChakraProvider>

      <BrowserRouter>
      {/* <AdminDashBoard/>  */}
      
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/virtualcenter" element={<VirtualCenter />} />
            <Route
              path="/virtualcenter/:virtual_username/*"tr5
              element={<NewVirtualCenter />}
            />
             <Route path="/virtualcenter/admin" element={
             <AdminLayout />
             } >
              <Route path="/virtualcenter/admin/login" element={<LoginPage />} />
              <Route path="/virtualcenter/admin/dashboard" element={<DashboardLayout />}  >
                {/* <Route path="/virtualcenter/admin" element={<Dashboard />}></Route> */}
                <Route path="/virtualcenter/admin/dashboard" element={<ProtectedRoute>
                  <AdminDashboard />
                </ProtectedRoute> } />
                <Route path="/virtualcenter/admin/dashboard/centers" element={<ProtectedRoute><Centers /></ProtectedRoute>} />
                <Route path="/virtualcenter/admin/dashboard/orders" element={<ProtectedRoute><Users /></ProtectedRoute>} />
                <Route path="/virtualcenter/admin/dashboard/prayerRequestAndTestimony" element={<ProtectedRoute><PrayerRequestAndTestimonies /></ProtectedRoute>} />
                <Route path="/virtualcenter/admin/dashboard/salvation" element={<ProtectedRoute><Salvation /></ProtectedRoute>} />
                <Route path="/virtualcenter/admin/dashboard/partner" element={<ProtectedRoute><Partner /></ProtectedRoute>} />
                <Route path="/virtualcenter/admin/dashboard/live-Stream" element={<ProtectedRoute><LiveStream /></ProtectedRoute>} />
                <Route path="/virtualcenter/admin/dashboard/lang-logs" element={<ProtectedRoute><LanguageLogs /></ProtectedRoute>} />
              </Route>
             
             </Route>
          </Routes>
        </Suspense>  
        
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
