import { configureStore } from "@reduxjs/toolkit";
import userInfo from "./userInfo";
import virtualcenterInfo from "./virtualcenterInfo";

export const store = configureStore({
  reducer: {
    userInfo,
    virtualcenterInfo,
  },
});
