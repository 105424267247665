import { Avatar, Rate, Space, Table, Typography } from "antd";
import { useEffect, useState } from "react";
import {  getPartners } from "../../API";

function Partner() {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    setLoading(true);
    getPartners().then((res) => {
      setDataSource(res.data);
    



      const allUsers = res.data.map(user => {
        return {
          id: user.id,
          email: user.user[0].email,
          phone:user.user[0].phone,
          full_name: user.user[0].full_name,
          createdAt: new Date( user.created_at).toLocaleDateString(),
        }
      });
      
      setDataSource(allUsers)
      setLoading(false)

    });
  }, []);

  return (
    <Space size={20} direction="vertical">
      <Typography.Title level={4}>Partners</Typography.Title>
      <Table
        loading={loading}
        columns={[
          {
            title: "Id",
            dataIndex: "id",
          },
          {
            title: "Full Name",
            dataIndex: "full_name",
          },
          {
            title: "Email",
            dataIndex: "email",
  
          },
          {
            title: "Phone",
            dataIndex: "phone",
          },
          {
            title: "Date Created",
            dataIndex: "createdAt",
          },
        ]}
        dataSource={dataSource}
        pagination={{
          pageSize: 5,
        }}
      ></Table>
    </Space>
  );
}
export default Partner;

  