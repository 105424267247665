import userEvent from "@testing-library/user-event";
import { Avatar, Rate, Space, Table, Typography } from "antd";
import { useEffect, useState } from "react";
import {  getSalvation } from "../../API";

function Salvation() {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    setLoading(true);
    getSalvation().then((res) => {
      setDataSource(res.data);

      const allUsers = res.data.map(user => {
        return {
          id: user.id,
          virtual_username: user.virtual_username,
          email: user.user[0].email,
          full_name: user.user[0].full_name,
        }
      });
      

      setDataSource(allUsers)
      setLoading(false)
    });

  }, []);

  return (
    <Space size={20} direction="vertical">
      <Typography.Title level={4}>Salvation</Typography.Title>
      <Table
        loading={loading}
        columns={[
          {
            title: "Id",
            dataIndex: "id",
          },
          {
            title: "Virtual Username",
            dataIndex: "virtual_username",
            render: (value) => <span>{value}</span>,
          },
          
          {
            title: "user",
            dataIndex: "full_name",
        
          },
          {
            title: "Email",
            dataIndex: "email",
          },
         
        ]}
        dataSource={dataSource}
        pagination={{
          pageSize: 5,
        }}
      ></Table>
    </Space>
  );
}
export default Salvation;
