import {
  AppstoreOutlined,
  UserOutlined,
  UsergroupAddOutlined,
  FireOutlined,  
  ReadOutlined,
  ClusterOutlined,
    
} from "@ant-design/icons";
import { Menu } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function SideMenu() {
  const location = useLocation();
  const [selectedKeys, setSelectedKeys] = useState("/");

  useEffect(() => {
    const pathName = location.pathname;
    setSelectedKeys(pathName);
  }, [location.pathname]);

  const navigate = useNavigate();
  return (
    <div className="SideMenu">
      <Menu
        className="SideMenuVertical"
        mode="vertical"
        onClick={(item) => {
          //item.key
          navigate(item.key);
        }}
        selectedKeys={[selectedKeys]}
        items={[
          {
            label: "Dashbaord",
            icon: <AppstoreOutlined />,
            key: "/virtualcenter/admin/dashboard",
          },
          {
            label: "Centers",
            key: "/virtualcenter/admin/dashboard/centers",
            icon: <ClusterOutlined />,
          },
          {
            label: "All Users",
            key: "/virtualcenter/admin/dashboard/orders",
            icon: <UserOutlined />,
          },
          {
            label: "Prayer&Test",
            key: "/virtualcenter/admin/dashboard/prayerRequestAndTestimony",
            icon: <ReadOutlined />,
          },
          {
            label: "Salvation",
            key: "/virtualcenter/admin/dashboard/salvation",
            icon: <FireOutlined />,
          },
          {
            label: "Partners",
            key: "/virtualcenter/admin/dashboard/partner",
            icon: <UsergroupAddOutlined />,
          },
          {
            label: "Languages",
            key: "/virtualcenter/admin/dashboard/lang-logs",
            icon: <UsergroupAddOutlined />,
          },
          {
            label: "Stream Link",
            key: "/virtualcenter/admin/dashboard/live-Stream",
            icon: <ClusterOutlined />,
          },
        ]}
      ></Menu>
    </div>
  );
}
export default SideMenu;
