import { Avatar, Rate, Space, Table, Typography } from "antd";
import { useEffect, useState } from "react";
import { getCenters } from "../../API";

function Centers() {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    setLoading(true);
    getCenters().then((res) => {
      setDataSource(res.data);
      setLoading(false);
     
    });
  }, []);

  return (
    <Space size={20} direction="vertical">
      <Typography.Title level={4}>Virtual Centers</Typography.Title>
      <Table
        loading={loading}
        columns={[
          {
            title: "Id",
            dataIndex: "id",
           
          },
          {
            title: "Center Name",
            dataIndex: "name",
          },
          {
            title: " Username",
            dataIndex: "virtual_username",
            
          },
          {
            title: "Type",
            dataIndex: "type",
          
          },
          {
            title: "Date Created",
            dataIndex: "created_at",
            render: (value) => <span>{
             new Date(value).toLocaleDateString()
              
              }</span>
            
          },

          
        ]}
        dataSource={dataSource}
        pagination={{
          pageSize: 5,
        }}
      ></Table>
    </Space>
  );
}
export default Centers;
