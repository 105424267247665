import { Avatar, Rate, Space, Table, Typography } from "antd";
import { useEffect, useState } from "react";
import {  getAllUsers } from "../../API";

function Users() {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    setLoading(true);
    getAllUsers().then((res) => {
      setDataSource(res.data);
      setLoading(false);
    });
  }, []);

  

  return (
    <Space size={20} direction="vertical">
      <Typography.Title level={4}>All Users</Typography.Title>
      <Table
        loading={loading}
        columns={[
          {
            title: "Id",
            dataIndex: "id",
          },
          {
            title: "Name",
            dataIndex: "full_name",
                                  
          },
          {
            title: "Email",
            dataIndex: "email",
            render: (value) => <span>{value}</span>,
          },
          {
            title: "Phone",
            dataIndex: "phone",
          },
          {
            title: "Date Created",
            dataIndex: "created_at",
            render: (value) => <span>{
             new Date(value).toLocaleDateString()
              
              }</span>
            
          },
        ]}
        dataSource={dataSource}
        pagination={{
          pageSize: 5,
        }}
      ></Table>
    </Space>
  );
}
export default Users;
