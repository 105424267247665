import { EditOutlined, GiftOutlined } from "@ant-design/icons";
import { Avatar, Rate, Space, Table, Typography, Modal } from "antd";
import { useEffect, useState } from "react";
import { getPrayerAndTest } from "../../../API";

function PrayerRequestAndTestimonies() {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [idstate, setIdState] = useState([]);

  useEffect(() => {
    setLoading(true);
    getPrayerAndTest().then((res) => {
      // setDataSource(res.data);


      const allUsers = res.data.map(user => {

        return {
          id: user?.id,
          virtual_username: user.virtual_username,
          message: user.msg,
          createdAt: new Date(user.created_at).toLocaleDateString(),
          email: user.user[0].email,
          full_name: user.user[0].full_name,
          request: user?.request?.name
        }
      });

      setDataSource(allUsers)
      setLoading(false)



    });
  }, []);

  console.log(dataSource, "DATA")
  console.log(idstate, "IDs")





  const onView = (record) => {
    Modal.confirm({

      okText: "Yes",
      okType: "danger",
      title: record.full_name,
      content: record.message,
      onOk: () => {
      },

    })
  }


  return (
    <Space size={20} direction="vertical">
      <Typography.Title level={4}>Prayer Request And Testimonies</Typography.Title>
      <Table
        loading={loading}
        columns={[
          {
            title: "Id",
            dataIndex: "id",

          },

          {
            title: "Request Type",
            dataIndex: "request",
          },
          {
            title: "Virtual Username",
            dataIndex: "virtual_username",
          },
          {
            title: "Full Name",
            dataIndex: "full_name",
          },
          {
            title: "Prayer/Testimony",
            dataIndex: "message",
            render: (value) => <span>{value.slice(0, 10)}........</span>,
          },
          {
            title: "Click to view Request",
            render: (record) => {
              console.log(record, "bybj")
              return <>
                <GiftOutlined onClick={() => { onView(record) }} />

              </>
            }
          },


        ]}
        dataSource={dataSource}
        pagination={{
          pageSize: 5,
        }}
      ></Table>

    </Space>
  );
}

export default PrayerRequestAndTestimonies;
