import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios"
import { useToast } from '@chakra-ui/react'
import { Link } from "react-router-dom";
import logo1 from "./logo.jpeg";


import {
  Container,
  LoginPageStyle,
  LogoWrapper,
} from "../../styles/LoginStyles";
import ThreeDots from "react-loading-icons/dist/esm/components/three-dots";

export const Login = () => {
  const toast = useToast();
  const [showLoading, setShowLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();


  function showToast(title, msg, status) {
    toast({
      title: title,
      description: msg,
      status: status,
      duration: 5000,
      isClosable: true,
      position: "top"
    });
    // setGravity(Gravity.TOP, 0, 0);
  }



  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowLoading(true)
    try {
      const response = await axios.post('https://api.ismvirtualcenter.org/api/admin/auth/login', {
        email,
        password
      });
      console.log(response)
      console.log(response.data.status_code)
      if (response.data.status_code === 200) {
        setShowLoading(false)
        showToast('Success', 'Login Successful', 'success');
        setTimeout(() => {
          localStorage.setItem("token", response.data.token.original.access_token);
          navigate('/virtualcenter/admin/dashboard')
          window.location.reload();
        }, 1000)

      } else {
        showToast('Fail', 'Incorrect Password', 'error');
        // Handle registration error
        console.error('Registration failed');
        setShowLoading(false);
      }
    } catch (error) {
      toast.error(error.response.data.Error, {
        autoClose: 3000,
      });
      throw new Error(`${error}`);
    }
  };

  return (
    <LoginPageStyle>
      {/* <BackDiv /> */}
      <Container>
        <div className="container">
          <Link to="/">
            <LogoWrapper>
              <img src={logo1} alt="logo" className="logo" />
            </LogoWrapper>
          </Link>

          <h2 className="login-header-text">Login</h2>

          <form action="" className="form-group" onSubmit={handleSubmit}>

            <div className="input-element">
              <label htmlFor="">Email</label>

              <input
                type="email"
                id="email"
                value={email}
                placeholder="Enter your email"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="input-element">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button disabled={showLoading} type="submit" className="login-btn">
              {!showLoading ? <span style={{
                background: "rgb(63,94,251)",
                background: "radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 53%)"
              }}>Login</span> :
                <ThreeDots height="1rem" fill="#fff" />}
            </button>
          </form>
        </div>
      </Container>
    </LoginPageStyle>
  );
};
