import React from "react";
import { useLocation, Navigate, Route } from "react-router-dom";

export const ProtectedRoute = ({ children }) => {
  const location = useLocation();
  const isAuthenticated = localStorage.getItem("token");

  if (!isAuthenticated) {
    return <Navigate to="/virtualcenter/admin/login" state={{ from: location }} replace />;
  }

  return children;
};


// export const ProtectedRoute = ({ children, exact, ...rest }) => {
//   // const location = useLocation();
//   const isAuthenticated = localStorage.getItem("token");

//   // if (!isAuthenticated) {
//   //   return <Navigate to="/virtualcenter/admin/login" state={{ from: location }} replace />;
//   // }

//   return <Route
//   {...rest}
//   exact
//   render={
//     ({location})=>
//     isAuthenticated ? children : <Navigate to="/virtualcenter/admin/login" state={{ from: location }} replace />
//   }
//   >
//     {children}
//   </Route>
// };