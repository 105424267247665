const  token = localStorage.getItem("token")

export const getCenters =()=>{
  return fetch("https://api.ismvirtualcenter.org/api/admin/virtual-centre",{
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).then((res)=> res.json());
}
export const getAllUsers =()=>{
  return fetch("https://api.ismvirtualcenter.org/api/admin/users",{
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).then((res)=> res.json());
}


export const getPrayerAndTest =()=>{
  return fetch("https://api.ismvirtualcenter.org/api/admin/virtual-centre-requests",{
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).then((res)=> res.json());
}

export const getSalvation =()=>{
  return fetch("https://api.ismvirtualcenter.org/api/admin/salvation",{  
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).then((res)=> res.json());
}


export const getLanguages =()=>{
  return fetch("https://api.ismvirtualcenter.org/api/admin/lang-logs",{  
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).then((res)=> res.json());
}





export const getPartners =()=>{
  return fetch("https://api.ismvirtualcenter.org/api/admin/partner",{
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).then((res)=> res.json());
}

